import React, { useEffect, useState, useRef } from "react";
import { CContainer, CRow, CCol } from "@coreui/react";
import { Link, useLocation, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  COffcanvas,
  COffcanvasBody,
  COffcanvasHeader,
  CCloseButton,
} from "@coreui/react";
import { CButton } from "@coreui/react";

// Components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CopyRight from "../components/CopyRight";
import avatar from "../assets/images/profile_avatar.png";
import Spinner from "./loader/Spinner";
import "./loader/Spinner.css";

// Singleton socket instance (moved outside the component)
const socketUrl = process.env.REACT_APP_Socket_URL;
const socket = io(`${socketUrl}`, {
  transports: ["websocket"],
});

function ChatThreads() {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const { userid } = useParams();
  const [receiverId, setReceiverId] = useState(null);
  const senderId = localStorage.getItem("userid");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [chatThreads, setChatThreads] = useState([]);
  const [senderImg, setSenderImg] = useState("");
  const [senderName, setSenderName] = useState("");
  const [receiverImg, setReceiverImg] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const imgeUrl = process.env.REACT_APP_IMAGE_URL;
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const language = localStorage.getItem("language");
  const [chatHistoryLoaded, setChatHistoryLoaded] = useState(false);
  const [activeThreadId, setActiveThreadId] = useState(null);
  const [loadingChats, setLoadingChats] = useState(true);
  const chatCardBodyRef = useRef(null);

  const location = useLocation();
  // Loaders
  const [isChatHistoryLoading, setIsChatHistoryLoading] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isChatThreadsLoading, setIsChatThreadsLoading] = useState(false);
  const [messageBeingSent, setMessageBeingSent] = useState(null);

  // --- Initialize sender data ---
  useEffect(() => {
    const userImg = localStorage.getItem("userImg");
    const userData = JSON.parse(localStorage.getItem("user") || "{}");
    setSenderName(`${userData.first_name || ""} ${userData.sir_name || ""}`);
    setSenderImg(userImg || avatar);

    // Connect the socket only once when the component mounts
    if (!socket.connected) {
      socket.connect();
    }

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (location.state?.threadId) {
      const threadId = location.state.threadId;
      const matchingThread = chatThreads.find(
        (thread) => thread._id === threadId
      );

      console.log("Matching thread:", matchingThread);

      if (matchingThread) {
        const receiverId =
          matchingThread.sender_id?._id === senderId
            ? matchingThread.receiver_id._id
            : matchingThread.sender_id._id;
            setVisible(false);
        setActiveThreadId(threadId);
        setReceiverId(receiverId);
        fetchChatHistory(senderId, receiverId);
        fetchUserData(receiverId);
     
        setChatHistoryLoaded(true);
      }
    }
  }, [location.state, chatThreads]);

  useEffect(() => {
    // Auto-scroll to the bottom when messages update
    if (chatCardBodyRef.current) {
      chatCardBodyRef.current.scrollTop = chatCardBodyRef.current.scrollHeight;
    }
  }, [messages]);

  // --- Fetch user data ---
  const fetchUserData = async (userId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${apiUrl}/get-user`,
        { user_id: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "accept-language": language,
          },
        }
      );

      if (response.data.result === "success") {
        const userDetails = response.data.data.userDetail;
        setReceiverImg(userDetails?.profile_image || avatar);
        setReceiverName(
          `${response.data.data.first_name} ${response.data.data.sir_name}`
        );
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // --- Fetch chat history ---
  const fetchChatHistory = (senderId, receiverId) => {
    setIsChatHistoryLoading(false);
    setLoadingChats(false);
    socket.emit("get-chat-history", {
      sender_id: senderId,
      receiver_id: receiverId,
    });
    socket.emit("register", { user_id: senderId });
  };

  // --- Handle socket events ---
  useEffect(() => {

    console.log("sender id :",senderId, "reciever id :",receiverId )
    const handleConnect = () => {
      console.log("Socket connected:", socket.id);
      setIsChatThreadsLoading(true);
      socket.emit("get-chat-threads", { user_id: senderId });
      if (receiverId) fetchChatHistory(senderId, receiverId);
    };

    const handleMessage = (message) => {
      console.log("Message received:", message);
      setIsSendingMessage(false);

      if (message.data.data.sender_id === senderId) return;
      if (message.data.data.sender_id === receiverId) {
        setMessages((prev) => [...prev, message.data.data]);
      }
    };

    const handleChatHistory = (response) => {
      setLoadingChats(false);
      console.log("Chat history received:", response);
      setIsChatHistoryLoading(false);
      if (response.result === "success") {
        setMessages(response.data);
      }
    };

    // const handleChatThreads = (response) => {
    //   setLoadingChats(false);
    //   console.log("Chat threads received:", response);
    //   setIsChatThreadsLoading(false);
    //   console.log(response.data, "dsfdsf");
    //   if (response.result === "success") {
    //     setChatThreads(response.data.data);
    //   }
    // };


    const handleChatThreads = (response) => {
      setIsChatThreadsLoading(false);
      if (response.result === "success") {
        const threads = response.data.data;
        setChatThreads(threads);
  
        // Automatically activate the first thread
        if (threads.length > 0) {
          const firstThread = threads[0];
          const firstReceiverId =
            firstThread.sender_id._id === senderId
              ? firstThread.receiver_id._id
              : firstThread.sender_id._id;
  
          setActiveThreadId(firstThread._id);
          setReceiverId(firstReceiverId);
          fetchChatHistory(senderId, firstReceiverId);
          fetchUserData(firstReceiverId);
        }
      }
    };
    socket.on("connect", handleConnect);
    socket.on(`${senderId}-send-message`, handleMessage);
    socket.on(`${senderId}-${receiverId}-get-chat-history`, handleChatHistory);
    socket.on(`${senderId}-get-chat-threads`, handleChatThreads);

    return () => {
      socket.off("connect", handleConnect);
      socket.off(`${senderId}-send-message`, handleMessage);
      socket.off(
        `${senderId}-${receiverId}-get-chat-history`,
        handleChatHistory
      );
      socket.off(`${senderId}-get-chat-threads`, handleChatThreads);
    };
  }, [senderId, receiverId]);

  // --- Send message ---
  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage) {
      const messageData = {
        sender_id: senderId,
        receiver_id: receiverId,
        message: newMessage,
      };
      setIsSendingMessage(true);
      setMessages((prev) => [...prev, messageData]);
      setNewMessage("");
      socket.emit("send-message", messageData);

      if (chatCardBodyRef.current) {
        chatCardBodyRef.current.scrollTop =
          chatCardBodyRef.current.scrollHeight;
      }
    }
  };

  // --- Handle thread click ---
  // const handleThreadClick = (thread, threadId) => {
  //   console.log(thread, "threaddata");
  //   setVisible(false);
  //   setReceiverId(thread);
  //   setActiveThreadId(threadId);
  //   fetchChatHistory(senderId, thread);
  //   fetchUserData(thread);
  //   setChatHistoryLoaded(true);
  //   setIsChatHistoryLoading(true);
 
  // };

  // --- Handle thread click ---
const handleThreadClick = async (thread, threadId) => {
  console.log(thread, "threaddata");
  setVisible(false);
  setReceiverId(thread);
  setActiveThreadId(threadId);
  setIsChatHistoryLoading(true);
  setChatHistoryLoaded(false);

  try {
    // Fetch user data and chat history in parallel
    const userDataPromise = fetchUserData(thread);
    const chatHistoryPromise = new Promise((resolve, reject) => {
      socket.emit("get-chat-history", {
        sender_id: senderId,
        receiver_id: thread,
      });

      const chatHistoryHandler = (response) => {
        if (response.result === "success") {
          setMessages(response.data);
          socket.off(`${senderId}-${thread}-get-chat-history`, chatHistoryHandler);
          resolve();
        } else {
          reject(new Error("Failed to fetch chat history"));
        }
      };

      socket.on(`${senderId}-${thread}-get-chat-history`, chatHistoryHandler);
    });

    // Wait for both operations to complete
    await Promise.all([userDataPromise, chatHistoryPromise]);

    setChatHistoryLoaded(true);
    setIsChatHistoryLoading(false);
  } catch (error) {
    console.error("Error loading thread data:", error);
    setIsChatHistoryLoading(false);
  }
};


  return (
    <>
      {/*/--- navbar ---/*/}
      <Navbar authorized={true} />
      {loadingChats?
                <div className="overlay-loader  chat-screen-wrapper chat-screen-wrapperloader position-relative">
                  <Spinner />
                  </div>
                  :
                  <>
          {chatThreads.length > 0 ? (
            <section className="chat-screen-wrapper">
              <CContainer>
                <div className='chat-screen-content'>
                  <CRow>
                    <CCol md={12}>
                      <h2>{t("Your Messages")}</h2>
                    </CCol>
                    <CCol lg={4} className="mt-5">
                      <div className="edit-profile-sidebar">
                        <CButton
                          className="d-lg-none w-100 profile-sidebar-toggler"
                          onClick={() => setVisible(true)}
                        >
                          {" "}
                          {t("Available Chats")} »
                        </CButton>
                        <COffcanvas
                          responsive="lg"
                          expand="lg"
                          dark
                          placement="start"
                          visible={visible}
                          onHide={() => setVisible(false)}
                          backdrop={false}
                        >
                          <COffcanvasHeader className="justify-content-end">
                            <CCloseButton
                              className="text-reset"
                              onClick={() => setVisible(false)}
                            />
                          </COffcanvasHeader>
                          <COffcanvasBody>
                            <div className="users-details-wrapper">
                              <div className="users-details-content">
                                {chatThreads
                                  .filter(
                                    (thread) =>
                                      thread.sender_id?._id !==
                                      thread.receiver_id?._id 
                                  )
                                  .map((thread) => {
                                    let profileImage;
                                    let firstName;
                                    let sirName;
                                    let lastMessage = thread.last_message;
                                    let receiver;
                                    let threadId;

                                    if (thread.sender_id?._id === senderId) {
                                      profileImage =
                                        thread.receiver_id?.userDetail
                                          ?.profile_image; // Optional chaining
                                      firstName =
                                        thread.receiver_id?.first_name; // Optional chaining
                                      sirName = thread.receiver_id?.sir_name; // Optional chaining
                                      receiver = thread.receiver_id?._id; // Optional chaining
                                      threadId = thread._id;
                                    } else {
                                      profileImage =
                                        thread.sender_id?.userDetail
                                          ?.profile_image; // Optional chaining
                                      firstName = thread.sender_id?.first_name; // Optional chaining
                                      sirName = thread.sender_id?.sir_name; // Optional chaining
                                      receiver = thread.sender_id?._id; // Optional chaining
                                      threadId = thread._id;
                                    }

                                    {
                                      /* if (thread.sender_id?._id === senderId) {
                                    // Use optional chaining to handle null checks
                                    profileImage =
                                      thread.receiver_id?.userDetail
                                        ?.profile_image; // Optional chaining
                                    firstName = thread.receiver_id?.first_name; // Optional chaining
                                    sirName = thread.receiver_id?.sir_name; // Optional chaining
                                    receiver = thread.receiver_id?._id; // Optional chaining
                                    threadId = thread._id;
                                  } else {
                                    profileImage =
                                      thread.sender_id?.userDetail
                                        ?.profile_image; // Optional chaining
                                    firstName = thread.sender_id?.first_name; // Optional chaining
                                    sirName = thread.sender_id?.sir_name; // Optional chaining
                                    receiver = thread.sender_id?._id; // Optional chaining
                                    threadId = thread._id;
                                  } */
                                    }

                                    return (
                                      <div
                                        key={thread._id}
                                        className={`single-user-detail-card mb-2 ps-2 ${
                                          activeThreadId === thread._id
                                            ? "active"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleThreadClick(receiver, threadId)
                                        }
                                      >
                                        <CRow className="align-items-center h-100-p w-100 m-0">
                                          <CCol lg={3} xs={3} md={3} sm={3}>
                                            <div className="single-user-dp">
                                              {profileImage ? (
                                                <img
                                                  src={`${imgeUrl}/${profileImage}`}
                                                  onError={(e) => {
                                                    e.target.src = avatar;
                                                  }}
                                                  alt="dp"
                                                />
                                              ) : (
                                                <img src={avatar} alt="dp" />
                                              )}
                                            </div>
                                          </CCol>
                                          <CCol lg={9} xs={9} md={9} sm={9}>
                                            <div className="single-user-desc">
                                              <div className="single-user-name">
                                                {firstName} {sirName}
                                              </div>
                                              <div className="last-message">
                                                {lastMessage}
                                              </div>
                                            </div>
                                          </CCol>
                                        </CRow>
                                      </div>
                                    );
                                  })}

                                {/* {isChatThreadsLoading && <Spinner />} */}
                              </div>
                            </div>
                          </COffcanvasBody>
                        </COffcanvas>
                      </div>
                    </CCol>
                    <CCol lg={8} className="mt-5">
                      <div className="users-chats-wrapper">
                        {/*/--- chat card header start here ---/*/}
                        {chatHistoryLoaded ? (

                          <div className="chat-card-header">
                            <div className="active-user-desc">
                              {receiverName}{" "}
                              <span className="active-dot"></span>
                            </div>
                          </div>
                        ) : (
                           ''
                        )}
                        {/*/--- chat card header end's here ---/*/}

                        {/*/--- message content start here ---/*/}
                        <div className="chat-card-body" ref={chatCardBodyRef}>
                          {isChatHistoryLoading ? (
                            // <div className="loader text-white mt-5 text-center">Loading chat history...</div>
                            <Spinner />
                          ) : (
                            <div className="chat-user">
                              {messages.map((msg, index) => (
                                <div
                                  key={index}
                                  className={
                                    msg.sender_id === senderId
                                      ? "sender"
                                      : "reciver"
                                  }
                                >
                                  {msg.sender_id === senderId ? (
                                    <>
                                      {messageBeingSent &&
                                      messageBeingSent.message ===
                                        msg.message &&
                                      isSendingMessage ? (
                                        <div className="me-3">
                                          <Spinner />
                                        </div>
                                      ) : null}

                                      <div className="sender-message-content me-2">
                                        {msg.message}
                                      </div>

                                      <div className="sender-img">
                                        {senderImg &&
                                        senderImg !== "null" &&
                                        senderImg !== null ? (
                                          <>
                                            {console.log(
                                              "senderImg : ",
                                              senderImg
                                            )}
                                            <Link
                                              to={`/user-profile/${senderId}/${senderName}`}
                                              onClick={() =>
                                                localStorage.setItem(
                                                  "targetUserId",
                                                  senderId
                                                )
                                              }
                                            >
                                              <img
                                                src={`${imgeUrl}/${senderImg}`}
                                                alt="user"
                                              />
                                            </Link>
                                          </>
                                        ) : (
                                          
                                          <Link
                                            to={`/user-profile/${senderId}/${senderName}`}
                                            onClick={() =>
                                              localStorage.setItem(
                                                "targetUserId",
                                                senderId
                                              )
                                            }
                                          >
                                            <img src={avatar} alt="avatar" />
                                          </Link>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="reciever-img me-2">
                                        {receiverImg &&
                                        receiverImg !== "null" ? (
                                          <Link
                                            to={`/user-profile/${receiverId}/${receiverName}`}
                                            onClick={() =>
                                              localStorage.setItem(
                                                "targetUserId",
                                                receiverId
                                              )
                                            }
                                          >
                                            <img
                                              src={`${imgeUrl}/${receiverImg}`}
                                              onError={(e) => {
                                                e.target.src = avatar;
                                              }}
                                              alt="user"
                                            />
                                          </Link>
                                        ) : (
                                          <Link
                                            to={`/user-profile/${receiverId}/${receiverName}`}
                                            onClick={() =>
                                              localStorage.setItem(
                                                "targetUserId",
                                                receiverId
                                              )
                                            }
                                          >
                                            <img src={avatar} alt="avatar" />
                                          </Link>
                                        )}
                                      </div>
                                      <div className="reciever-message-content">
                                        {msg.message}
                                      </div>
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                          {/*/--- typing input start's here ---/*/}
                          {chatHistoryLoaded && (
                            <form>
                              <div className="typing-input-wrapper">
                                <input
                                  type="text"
                                  placeholder={t("Type here...")}
                                  value={newMessage}
                                  onChange={(e) =>
                                    setNewMessage(e.target.value)
                                  }
                                />
                                <button
                                  type="submit"
                                  className="send-btn"
                                  disabled={!chatHistoryLoaded || !newMessage}
                                  onClick={(e) => handleSendMessage(e)}
                                >
                                  {/* {isSendingMessage ? `${t('Sending...')}` : `${t('Send')}`} */}
                                  {t("Send")}
                                </button>
                              </div>
                            </form>
                          )}
                          {/*/--- typing input end's here ---/*/}
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </CContainer>
            </section>
          ) : (
            <section className="chat-screen-wrapper chat-screen-wrapperloader position-relative">
            
    
              {chatThreads.length === 0 && !loadingChats && (
                <div
                  className="w-100 my-5 d-flex align-items-center justify-content-center"
                  style={{ height: "100vh" }}
                >
                  No chats found!
                </div>
             
              )}
             
            
            </section>
          )}</>
         
      }
        {/* </>
      )} */}

      {/*/--- Footer ---/*/}
      <Footer />

      {/*/--- copy right ---/*/}
      <CopyRight />
    </>
  );
}

export default ChatThreads;
